import React from "react";
import Layout from "../components/layout";
import BlueSlash from "../components/blueSlash";
import DesignNotes from "../images/undraw_design_notes_8dmv.svg";
import ContactUs from "../components/contactUs";

const Services = () => {
  const renderService = (title, paragraph, id) => (
    <>
      <div id={id} style={{ height: "3rem" }}></div>
      <h2
        className="column is-half has-text-primary title has-text-right has-text-left-mobile"
        style={{
          marginBottom: "0rem",
          paddingRight: "3rem",
        }}
      >
        {title}
      </h2>
      <div className="column is-half is-size-5" style={{ maxWidth: "600px" }}>
        {paragraph.map((para, index) => (
          <p key={index} style={{ marginBottom: "1.5rem", lineHeight: 1.6 }}>
            {para}
          </p>
        ))}
      </div>
    </>
  );

  return (
    <Layout>
      <BlueSlash />
      <section className="section">
        <div className="container">
          <div className="columns level">
            <div className="column">
              <h1 className="title is-size-1 has-text-primary has-text-centered">
                Our Services
              </h1>
              <p
                className="subtitle has-text-centered is-size-4"
                style={{ maxWidth: "520px", margin: "auto", lineHeight: 1.6 }}
              >
                Our team has years of experience working with the technology
                listed below, all of which we love to build with. We’ve found
                these technologies can solve a myriad of problems, creating
                software that you and your clients will love.
              </p>
            </div>
            <div className="column">
              <img
                src={DesignNotes}
                alt="A sketch of a man standing beside a web design"
                style={{
                  width: "200%",
                }}
              />
            </div>
          </div>
          <div
            className="columns is-multiline"
            style={{ margin: "auto", paddingTop: "3rem" }}
          >
            {renderService(
              "Pre-rendered sites & apps",
              [
                "Pre-rendered (or more commonly, static sites) are the fastest sites and apps on the web right now. They are extremely secure, great for SEO, easy to update and manage, and are growing in popularity.",
                "A pre-rendered site can stand up to enormous spikes in traffic. You never know when your site will go viral, be mentioned by someone famous, or simply receive a huge hit in traffic. Your pre-rendered site won’t slow down, it won’t crash and you won’t lose sales or potential customers.",
                "With modern technology, a pre-rendered site can do almost anything a traditional site can. You can have e-commerce, user portals, interactive content, etc.",
              ],
              "static"
            )}
            {renderService(
              "Serverless Infrastructure",
              [
                "The days of managing servers are gone. Serverless infrastructure is fast to build and deploy (we’re talking minutes). It’s affordable and scales up to any size needed.",
              ],
              "serverless"
            )}
            {renderService(
              "Progressive Web Apps (PWA)",
              [
                "A PWA is a website that acts like a native app on a phone or computer. You can install them from your browser on web or mobile. They are extremely easy to update, can be built in a fraction of the time of traditional apps, cost significantly less, and have a lot of the same features as traditional apps.",
                `You don’t have to deal with the app stores or creating two different code bases. One code base, deployed on the internet is your app.`,
              ],
              "pwa"
            )}
            {renderService(
              "JAMStack",
              [
                "JAMStack stands for Javascript Apis Markup. And it allows us to build lightning-fast websites and apps that are easy to update, cheap to host and maintain, perform flawlessly on SEO, and are very secure.",
                "You get all of the functionality you want from a traditional website with much better performance and user satisfaction.",
              ],
              "jam"
            )}
            {renderService(
              "Gatsby",
              [
                "Gatsby JS is a framework that we use to create static websites and apps. It uses React and GraphQl to enable us to make websites that are fast and easy to update and maintain.",
                "It also allows you to use any data source you want. If you have all of your blogs saved to Wordpress, or all of your products in Shopify, or even a Google Sheet full of data, we can grab that and put it into your website.",
              ],
              "gatsby"
            )}
            {renderService(
              "React",
              [
                "React is the most popular Front-end framework for modern sites. It allows developers to build really incredible experiences in a short amount of time. We happily use React in almost all of our projects.",
              ],
              "react"
            )}
            {renderService(
              "AWS",
              [
                "AWS has taken over the internet, and we can help you harness its power. Our team has been using AWS for years. We know the ins and outs of the good stuff and the bad stuff.",
              ],
              "aws"
            )}
          </div>
        </div>
        <ContactUs />
      </section>
    </Layout>
  );
};

export default Services;
