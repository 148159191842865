import React from "react";
import slashStyles from "./blueSlash.module.scss";

const BlueSlash = () => {
  return (
    <>
      <div className={`has-background-primary ${slashStyles.blueSpacer}`}></div>
      <div className={`header has-background-primary ${slashStyles.blueDiv}`}>
        <svg
          className={slashStyles.svg}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 100 100"
          preserveAspectRatio="none"
        >
          <polygon fill="white" points="-5,100 100,0 100,100" />
        </svg>
      </div>
      <div className={`has-background-primary ${slashStyles.spacerDiv}`}></div>
    </>
  );
};

export default BlueSlash;
