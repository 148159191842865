import React from "react";

const ContactUs = () => {
  return (
    <section className="section">
      <div
        className="container"
        style={{ margin: "5rem auto", maxWidth: "650px" }}
      >
        <div style={{ marginBottom: "3rem" }}>
          <h1 className="title is-size-1">Let's work together</h1>
          <p className="subtitle">
            We would love to help you with your next project
          </p>
        </div>
        <form
          name="contact"
          method="post"
          netlify-honeypot="bot-field"
          data-netlify="true"
        >
          <input type="hidden" name="bot-field" />
          <input type="hidden" name="form-name" value="contact" />
          <div className="columns is-multiline">
            <div className="column is-half">
              {" "}
              <div className="field">
                <label htmlFor="name" className="label">
                  Name
                </label>
                <div className="control">
                  <input
                    id="name"
                    name="name"
                    type="text"
                    className="input"
                    placeholder="Your Name"
                  />
                </div>
              </div>
            </div>
            <div className="column is-half">
              <div className="field">
                <label htmlFor="email" className="label">
                  Email
                </label>
                <div className="control">
                  <input
                    id="email"
                    type="email"
                    name="email"
                    className="input"
                    placeholder="Your email"
                  />
                </div>
              </div>
            </div>
            <div className="column is-half">
              <div className="field">
                <label htmlFor="phone" className="label">
                  Phone Number (optional)
                </label>
                <div className="control">
                  <input
                    id="phone"
                    type="phone"
                    name="phone"
                    className="input"
                    placeholder="Your phone number"
                  />
                </div>
              </div>
            </div>
            <div className="column is-half">
              <div className="field">
                <label htmlFor="company" className="label">
                  Company (optional)
                </label>
                <div className="control">
                  <input
                    id="company"
                    type="text"
                    name="company"
                    className="input"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="field">
            <label htmlFor="select" className="label">
              What is your budget?
            </label>
            <div className="control">
              <div className="select">
                <select id="select" name="budget">
                  <option>Select one...</option>
                  <option value="<5000">Less than $5,000</option>
                  <option value="5-10">$5,000 - $10,000</option>
                  <option value="10-30">$10,000 - $30,0000</option>
                  <option value="30-75">$30,000 - $75,0000</option>
                  <option value="75-100">$75,0000 - $100,000</option>
                  <option value="100+">More than $100,000</option>
                </select>
              </div>
            </div>
          </div>
          <div className="field">
            <label htmlFor="textarea" className="label">
              How can we help?
            </label>
            <div className="control">
              <textarea
                id="textarea"
                className="textarea"
                placeholder="Textarea"
                name="message"
              ></textarea>
            </div>
          </div>
          <button className="button is-danger is-large is-rounded">
            Connect With Us
          </button>
        </form>
      </div>
    </section>
  );
};

export default ContactUs;
